import React, { useEffect, useState } from "react";
import { AuthGet, Get, Post } from "../../_common/httpService";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toast";
import GreentickIcon from "../../assets/images/greentick_icon.svg";
import RedtickIcon from "../../assets/images/Close_red.svg";
import ConfigureIcon from "../../assets/images/configure_icon.svg";
import FilterIcon from "../../assets/images/Filter_icon.svg";
import UnsubscribeIcon from "../../assets/images/unsubscribe_icon.svg";
import BacktoofficeIcon from "../../assets/images/backto_office.svg";
import ConfigIcon from "../../assets/images/config_icon.svg";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../Context/StateProvider";
import PieChart from "../../Components/Charts/PieChart";
import BarChart from "../../Components/Charts/BarChart";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material";
import Modal from 'react-bootstrap/Modal';

const today = new Date();
let totalCounts = {
  borrowers: 0,
  providers: 0,
  loans: 0,
};

// UPDATE/SET BORROWER,LOANS AND PROVIDER PIE-CHARTS DATA
export const updatePieChartsData = (chartData, dispatch) => {
  // RESETTING TOTAL_COUNTS OBJECT
  totalCounts.borrowers = 0;
  totalCounts.loans = 0;
  totalCounts.providers = 0;

  let data = chartData;
  Object.keys(data).map((el) => {
    data[el].map((ele) => {
      totalCounts[el] += ele.count;
      ele.name = ele.vertical_name;
      delete ele.vertical_name;
    })
  })
  dispatch({
    type: 'SET_PIECHARTS_DATA',
    payload: data
  })
}

// UPDATE/SET AVERAGE LOAN AMOUNT BAR-CHART DATA
export const updateAvgLoansData = (data, dispatch) => {
  let avgLoanRespData = data;
  avgLoanRespData?.map((el) => {
    el.name = el.vertical_name;
    el.amount = el.avg_loanamount;
    delete el.vertical_name;
    delete el.avg_loanamount;
  })
  dispatch({
    type: 'SET_AVGLOANS_DATA',
    payload: avgLoanRespData
  })
}

// UPDATE/SET LOAN DISBURSED MONTH WISE BAR-CHART DATA
export const updateLoanDisbursedData = (data, dispatch) => {
  let loanDisbursedRespData = data;
  loanDisbursedRespData?.map((el) => {
    el.name = el.vertical_name;
    el.amount = el.count;
    delete el.vertical_name;
    delete el.count;
  })
  dispatch({
    type: 'SET_MONTHWISE_DISBURSED',
    payload: loanDisbursedRespData
  })
}

const HomeLanding = () => {
  const navigate = useNavigate();
  let tenant = sessionStorage.getItem("tenantId");
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [{pieChartsData, averageLoansData, monthWiseLoansDisbursed, currency_symbol}, dispatch] = useStateValue();
  let tenantToken = sessionStorage.getItem("tenantToken");
  const noPreview = "No Chart Preview Available";
  const [isOpen, setIsOpen] = useState(false);
  const [selectedmonth, handleMonthChange] = useState(today);
  const [unsubModalShow, setUnsubModalShow] = useState(false);
  const [unsubscribeId, setUnsubscribeId] = useState("");

  const getAllSubscriptions = async () => {
    try {
      let resp = await AuthGet(`subscription/getsubscriptionList/${tenant}`);
      if (resp.statusCode === 200) {
        let {subscriptions} = resp.data;
        sessionStorage.setItem(
          "subscriptionId",
          subscriptions[0].subscription_id
        );
        dispatch({
          type: 'SET_CURRENCY_SYMBOL',
          payload: subscriptions[0].currency_symbol
        })
        sessionStorage.setItem("email", subscriptions[0].email)
        setAllSubscriptions(subscriptions);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  useEffect(() => {
    getAllSubscriptions();
    getChartsData();
  }, [])

  const UnSubscribe = async (subscription_id) => {
    try {
      let resp = await Post(`configuration/unsubscribe/${subscription_id}`);
      if (resp) {
        setUnsubModalShow(false);
        getAllSubscriptions();
        toast.success("Unsubscribed");
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const getStage = (id) => {
    Get(`subscription/getstage/${id}`)
      .then((res) => {
        sessionStorage.setItem("ProductId", res["data"][0]["productId"]);
        sessionStorage.setItem("VerticalId", res["data"][0]["verticalId"]);
        sessionStorage.setItem("tenantId", res["data"][0]["tenantId"]);
        if (res["data"][0]["step"] == 2) {
          let id = sessionStorage.getItem("VerticalId");
          navigate(`/vertical`);
        } else if (res["data"][0]["step"] == 3) {
          navigate("/upload-logo", { replace: true });
        } else if (res["data"][0]["step"] == 4) {
          navigate("/theme");
        } else if (res["data"][0]["step"] == 5) {
          navigate('/verify-otp');
        }
      })
      .catch((err) => {});
  };

  const gotoSteps = (e, id) => {
    sessionStorage.setItem("subscriptionId", id);
    getStage(id);
  };

  useEffect(() => {
    dispatch({type: "SET_STEP", payload: null});
    dispatch({type: "SET_CURRENT_STEP", payload: null});
  }, [])

  const openPortal = (bName, Email, subId) => {
    AuthGet(`tenant/checktenant/${subId}`)
      .then((res) => {
        if (res.statusCode == 200 && res.data.result == "verifiedtenant") {
          let email = encodeURIComponent(Email);
          let subscriptionId = encodeURIComponent(subId);
          window.open(
            `https://${bName}.${process.env.REACT_APP_ADMIN_URL_DOMAIN}/admin/${email}/${subscriptionId}`
          );
        } else {
          toast.error(
            "Your Activation in progress, Please contact Help@theecode.xyz"
          );
        }
      })
      .catch((err) => {});
  };

  const getChartsData = async () => {
    try {
      let pieChartResp = await AuthGet(`overview/fetch-dashboard-count/${tenant}`);
      let avgLoanResp = await AuthGet(`overview/fetch-avg-loan/${tenant}`);
      if(pieChartResp.statusCode === 200) {
        let data = pieChartResp?.data;
        updatePieChartsData(data, dispatch);
      }
      if (avgLoanResp.statusCode === 200) {
        let avgLoanRespData = avgLoanResp?.data;
        updateAvgLoansData(avgLoanRespData, dispatch);
      }
    } catch (error) {
      console.log('error::: ', error);
    }
  }

  const getLoanDisbursedData = async () => {
    try {
      let month = `${selectedmonth}`.slice(4,7);
      let year = `${selectedmonth}`.slice(11,15);
      let data = month + " " + year;
      let loanDisbursedResp = await AuthGet(`overview/loandisbursed-monthwise/${data}/${tenant}`);
      if (loanDisbursedResp.statusCode === 200) {
        let loanDisbursedRespData = loanDisbursedResp?.data;
        updateLoanDisbursedData(loanDisbursedRespData, dispatch);
      }
    } catch (error) {
      console.log('error::: ', error);
    }
  }

  useEffect(() => {
    getLoanDisbursedData();
  }, [selectedmonth])

  return (
    <div>
      <Toast />

      {/* UNSUBSCRIBE MODAL */}
      <Modal 
        show={unsubModalShow} 
        onHide={() => setUnsubModalShow(false)} 
        className="smallpopup"
      >
        <Modal.Header>
          <Modal.Title>⚠️ Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Are you sure to Unsubscribe this vertical?
          </div> 
        </Modal.Body>
        <Modal.Footer>
        <div>
            <button className="devilLip-btn devilLip-primaryClr"
              onClick={() => {
                setUnsubModalShow(false);
                setUnsubscribeId('');
              }}
            >
              No
            </button>
          </div>
          <div>
            <button className="devilLip-btn devilLip-primaryClr"
              onClick={() => UnSubscribe(unsubscribeId)}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="rectangleWhiteframe">
        <div style={{paddingTop: 0}} className="row">
          <div className="mb_16 fontWeight_600">My Subscriptions</div>
          {allSubscriptions?.map((subscription, index) => (
            <div className="col-md-4" key={index}>
              <div className="verticalItem">
                <table className="mb_24 vertical_detailtable">
                  <tr>
                    <th className="fontSize14 steelWoolText fontWeight_400">
                      Business name
                    </th>
                    <td className="fontSize14 neroText fontWeight_400">
                      {subscription?.business_name}
                    </td>
                  </tr>
                  <tr>
                    <th className="fontSize14 steelWoolText fontWeight_400">
                      Product
                    </th>
                    <td className="fontSize14 neroText fontWeight_400">
                      {subscription?.product_name}
                    </td>
                  </tr>
                  <tr>
                    <th className="fontSize14 steelWoolText fontWeight_400">
                      Publish Status
                    </th>
                    <td
                      className={`fontSize14 ${
                        subscription?.publish == "Published"
                          ? "verifyGreenTick"
                          : "UnverifyTick"
                      } fontWeight_400`}
                    >
                      <div className="displayFlex Alignitem_center justifyContent_End gap_12">
                        <span>{subscription?.publish}</span>
                        <img src={GreentickIcon} alt="GreentickIcon" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="fontSize14 steelWoolText fontWeight_400">
                      Subscription Status
                    </th>
                    <td
                      className={`fontSize14 ${
                        subscription?.subscription_status == "In Progress"
                          ? "UnverifyTick"
                          : "verifyGreenTick"
                      } fontWeight_400`}
                    >
                      <div className="displayFlex Alignitem_center justifyContent_End gap_12">
                        <span style={subscription?.subscription_status === "Unsubscribed" ? {color: '#d95867'} : {}}>{subscription?.subscription_status}</span>
                        <img src={subscription?.subscription_status === "Unsubscribed" ? RedtickIcon : GreentickIcon} alt="GreentickIcon" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className="fontSize14 steelWoolText fontWeight_400">
                      Subscribed on
                    </th>
                    <td className="fontSize14 neroText fontWeight_400">
                      {subscription?.created_date}
                    </td>
                  </tr>
                </table>
                <div className="cerebralGrey_bottomBorder mb_16"></div>
                {subscription?.subscription_status === "Unsubscribed" ? (
                  <div className="textAlign_center justifyContent_center fontSize14 steelWoolText height32 displayFlex Alignitem_center">
                    Unsubscribed
                  </div>
                ) : subscription?.step < 6 ? (
                  <>
                    <button
                      className="devilLip-btn devilLip-primaryClr"
                      onClick={(e) => gotoSteps(e, subscription?.subscription_id)}
                    >
                      Continue
                    </button>
                  </>
                ) : (
                  // <div className="displayFlex Alignitem_center flexWrap gap_12 justifyContent_spaceBetween">
                  //   <div>
                  //     <button
                  //       onClick={(e) =>
                  //         UnSubscribe(e, subscription?.subscription_id)
                  //       }
                  //       className="devilLip-btn devilLip-primaryHoverClr"
                  //     >
                  //       Unsubscribe
                  //     </button>
                  //   </div>
                  //   <div className="displayFlex Alignitem_center gap_8 flexWrap">
                  //     <div>
                  //       <button
                  //         onClick={() =>
                  //           navigate(
                  //             `/control-panel/dashboard/${subscription?.subscription_id}/${tenantToken}`
                  //           )
                  //         }
                  //         className="devilLip-btn devilLip-primaryClr"
                  //       >
                  //         <img
                  //           className="width_18"
                  //           src={ConfigureIcon}
                  //           alt="ConfigureIcon"
                  //         />
                  //         <span>Configure</span>
                  //       </button>
                  //     </div>
                  //     <div>
                  //       <button
                  //         onClick={() =>
                  //           openPortal(
                  //             subscription?.business_name,
                  //             subscription?.email,
                  //             subscription?.subscription_id
                  //           )
                  //         }
                  //         className="devilLip-btn devilLip-primaryClr"
                  //       >
                  //         Back Office
                  //       </button>
                  //     </div>
                  //   </div>
                  // </div>

                  <div className="verticalFooter">
                  <ul>
                    <li>
                      <button
                        onClick={(e) => {
                          setUnsubscribeId(subscription?.subscription_id);
                          setUnsubModalShow(true);
                        }}
                      >
                        <img src={UnsubscribeIcon} alt="UnsubscribeIcon" />
                        <span className="customTooltiptext">Unsubscribe</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          navigate(
                            `/control-panel/dashboard/${subscription?.subscription_id}/${tenantToken}`
                          )
                        }
                      >
                        <img src={ConfigIcon} alt="ConfigIcon" />
                        <span className="customTooltiptext">Configure</span>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          openPortal(
                            subscription?.business_name,
                            subscription?.email,
                            subscription?.subscription_id
                          )
                        }
                      >
                        <img src={BacktoofficeIcon} alt="BacktoofficeIcon" />
                        <span className="customTooltiptext">Back Office</span>
                      </button>
                    </li>
                  </ul>
                </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="rectangleWhiteframe">
            <div className="displayFlex Alignitem_center justifyContent_spaceBetween mb_24">
              <div>
                <h5 className="fontSize14 neroText fontWeight_500 ">
                No.of Borrowers (By Vertical) : <span className="fontWeight_600">{totalCounts.borrowers < 10 ? 0 + "" + totalCounts.borrowers : totalCounts.borrowers}</span>
                </h5>
                {/* <p className="fontSize14 devilLipText fontWeight_500">
                  
                </p> */}
              </div>
              {/* <div className="">
                <div className="GroupInput">
                  <div className="GroupInputText-prepend">
                    <span className="GroupInputText">
                      <img
                        src={FilterIcon}
                        className="width_14"
                        alt="FilterIcon"
                      />
                    </span>
                  </div>
                  <select className="width_100">
                    <option>Select</option>
                    <option>Prospects</option>
                    <option>Opportunities</option>
                    <option>Deals</option>
                    <option>Funded Contracts</option>
                  </select>
                </div>
              </div> */}
            </div>
            {/* PIE CHART FOR BORROWERS START */}
            <div className="pieChart">
              {pieChartsData?.borrowers?.length > 0 ? (
                <PieChart
                  label={"Borrowers"}
                  data={pieChartsData?.borrowers}
                  borderColor={"white"}
                  borderWidth={3}
                  hoverOffset={4}
                  radius={"70%"}
                />
              ) : (
                <p style={{ textAlign: "center", color: "grey", opacity: 0.5 }}>
                  {noPreview}
                </p>
              )}
            </div>
            {/* PIE CHART FOR BORROWERS END */}
          </div>
        </div>
        <div className="col-md-4">
          <div className="rectangleWhiteframe">
            <div className="displayFlex Alignitem_center justifyContent_spaceBetween mb_24">
              <div>
                <h5 className="fontSize14 neroText fontWeight_500 ">
                No.of Loans (By Vertical) : <span className="fontWeight_600">{totalCounts.loans < 10 ? 0 + "" + totalCounts.loans : totalCounts.loans}</span>
                </h5>
                {/* <p className="fontSize14 devilLipText fontWeight_500">
                  
                </p> */}
              </div>
              {/* <div className="">
                <div className="GroupInput">
                  <div className="GroupInputText-prepend">
                    <span className="GroupInputText">
                      <img
                        src={FilterIcon}
                        className="width_14"
                        alt="FilterIcon"
                      />
                    </span>
                  </div>
                  <select className="width_100">
                    <option>Select</option>
                    <option>Prospects</option>
                    <option>Opportunities</option>
                    <option>Deals</option>
                    <option>Funded Contracts</option>
                  </select>
                </div>
              </div> */}
            </div>
            {/* PIE CHART FOR LOANS START */}
            <div className="pieChart">
              {pieChartsData?.loans?.length > 0 ? (
                <PieChart
                  label={"Loans"}
                  data={pieChartsData?.loans}
                  borderColor={"white"}
                  borderWidth={3}
                  hoverOffset={4}
                  radius={"70%"}
                />
              ) : (
                <p style={{ textAlign: "center", color: "grey", opacity: 0.5 }}>
                  {noPreview}
                </p>
              )}
            </div>
            {/* PIE CHART FOR LOANS END */}
          </div>
        </div>
        <div className="col-md-4">
          <div className="rectangleWhiteframe">
            <div className="displayFlex Alignitem_center justifyContent_spaceBetween mb_24">
              <div>
                <h5 className="fontSize14 neroText fontWeight_500 ">
                  No.of Service Providers (By Vertical) : <span className="fontWeight_600">{totalCounts.providers < 10 ? 0 + "" + totalCounts.providers : totalCounts.providers}</span>
                </h5>
                {/* <p className="fontSize14 devilLipText fontWeight_500">
                  
                </p> */}
              </div>
              {/* <div className="">
                <div className="GroupInput">
                  <div className="GroupInputText-prepend">
                    <span className="GroupInputText">
                      <img
                        src={FilterIcon}
                        className="width_14"
                        alt="FilterIcon"
                      />
                    </span>
                  </div>
                  <select className="width_100">
                    <option>Select</option>
                    <option>Prospects</option>
                    <option>Opportunities</option>
                    <option>Deals</option>
                    <option>Funded Contracts</option>
                  </select>
                </div>
              </div> */}
            </div>
            {/* PIE CHART FOR DEALERS START */}
            <div className="pieChart">
              {pieChartsData?.providers?.length > 0 ? (
                <PieChart
                  label={"Dealers"}
                  data={pieChartsData?.providers}
                  borderColor={"white"}
                  borderWidth={3}
                  hoverOffset={4}
                  radius={"70%"}
                />
              ) : (
                <p style={{ textAlign: "center", color: "grey", opacity: 0.5 }}>
                  {noPreview}
                </p>
              )}
            </div>
            {/* PIE CHART FOR DEALERS END */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="rectangleWhiteframe height100per">
            <div className="mb_24">
              <div>
                <h5 className="fontSize14 neroText fontWeight_500 ">
                  Average Loan Amount (By Vertical)
                </h5>
              </div>
            </div>
            <div className="barChart">
              {averageLoansData.length > 0 ? (
                <BarChart
                  data={averageLoansData}
                  hoverOffset={4}
                  currencySymbol={currency_symbol}
                />
              ) : (
                <p style={{ textAlign: "center", color: "grey", opacity: 0.5 }}>
                  {noPreview}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="rectangleWhiteframe height100per">
            <div className="displayFlex justifyContent_spaceBetween">
              <div>
                <h5 className="fontSize14 neroText fontWeight_500 ">
                  No.of Loans disbursed month wise (By Vertical)
                </h5>
              </div>
              <div className="">
              <div className="GroupInput" style={{cursor: 'pointer'}} 
                onClick={() => setIsOpen(!isOpen)}>
                  <div className="GroupInputText-prepend">
                    <span className="GroupInputText">
                      <img
                        src={FilterIcon}
                        className="width_14"
                        alt="FilterIcon"
                      />
                    </span>
                  </div>
                  <select style={{cursor: 'pointer'}} 
                    onClick={() => setIsOpen(!isOpen)} className="width_100">
                    <option>{`${selectedmonth}`.slice(4,7) + " " + `${selectedmonth}`.slice(11,15)}</option>
                  </select>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'"month" and "year"'}
                    views={["month", "year"]}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    className="customMonthlyPicker"
                    value={selectedmonth}
                    maxDate={today}
                    onChange={(newvalue) => handleMonthChange(newvalue.$d)}
                    open={isOpen}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {monthWiseLoansDisbursed.length > 0 ? (
              <div className="barChart">
                <BarChart
                  data={monthWiseLoansDisbursed}
                  hoverOffset={4}
                />
              </div>
            ) : (
              <p style={{ textAlign: "center", color: "grey", opacity: 0.5 }}>
                {noPreview}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLanding;
