import React, { useEffect, useState } from "react";
import Stepper from "../../Components/Stepper";
import { useNavigate } from "react-router-dom";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import { useStateValue } from "../../Context/StateProvider";
import { AuthFile, AuthGet, AuthPost, Post } from "../../_common/httpService";
// import theecodetheme from "../../assets/images/subcategory-05.png";
import theecodelogo from "../../assets/images/logo_theecode.svg";
import brandlogo from "../../assets/images/brand_logo.svg";
import UserAvatar from "../../Components/UserAvatar/UserAvatar";

const Theme = () => {
  const [allTheme, setAllTheme] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({});
  const [isThemeExists, setIsThemExists] = useState(false);
  const [show, setshow] = useState("");
  const navigate = useNavigate();
  const [{}, dispatch] = useStateValue();
  let productId = sessionStorage.getItem("ProductId");
  let verticalId = sessionStorage.getItem("VerticalId");
  let tenantId = sessionStorage.getItem("tenantId");
  let subscriptionId = sessionStorage.getItem("subscriptionId");
  let themestyle = "";
  const addNewSubscription = JSON.parse(sessionStorage.getItem("newSubscription"));

  useEffect(() => {
    setshow(
      sessionStorage.getItem("build") ? sessionStorage.getItem("build") : "No"
    );
    if (sessionStorage.getItem("selectedthemeid")) {
      themestyle = sessionStorage.getItem("selectedthemeid");
    }
    AuthGet(`tenant-theme/list-theme/${subscriptionId}`)
      .then((res) => {
        if (res.data.tenants.length > 0) {
          setSelectedTenant(res.data.tenants[0]);
          setIsThemExists(true);
          sessionStorage.setItem("tenantId", res.data.tenants[0].tenantId);
        }
      })
      .catch((error) => console.log(error));

    AuthGet(`tenant-theme/themelist/${verticalId}/${productId}`)
      .then((res) => {
        if (res.statusCode !== undefined && res.statusCode === 200) {
          setAllTheme(res.data);
          setSelectedTheme(res.data[0]);
          let thetheme = [];

          if (sessionStorage.getItem("selectedthemeid")) {
            let filt = res.data.filter((theme) => {
              if (theme.id == themestyle) {
                thetheme.push(theme);
              }
              setSelectedTheme(thetheme[0], thetheme);
            });
          }
        }
      })
      .catch((err) => {});
  }, []);

  let handleSelect = (e, value) => {
    e.preventDefault();
    setSelectedTheme(value);
    sessionStorage.setItem("themeId", selectedTenant.id);
  };

  const publish = () => {
    let res = AuthGet(`configuration/list/${subscriptionId}`)
      .then((res) => {
        if (res.tenants) {
          const defaultValues = {
            plaidEnv: "sandbox",
            plaidClientId: process.env.REACT_APP_PLAID_CLIENT_ID,
            plaidSecret: process.env.REACT_APP_PLAID_SECRET_KEY,
            sendGridAPIKey: process.env.REACT_APP_SEND_GRID_KEY,
          };
          const details = res.tenants[0];
          const params = {
            PLAID_ENV: (details && details.plaidEnv) || defaultValues.plaidEnv,
            PLAID_CLIENT_ID:
              (details && details.plaidClientId) || defaultValues.plaidClientId,
            PLAID_SECRET_KEY:
              (details && details.plaidSecret) || defaultValues.plaidSecret,
            SENDGRID_KEY:
              (details && details.sendGridAPIKey) ||
              defaultValues.sendGridAPIKey,
            SUBSCRIPTION_ID: subscriptionId,
            BUSINESS_NAME: res.data.businessName
              .replace(/\s/g, "")
              .toLowerCase(),
            token: "qwerty201",
            STYLE: "style",
            THEECODE_KEY: res.data.key,
            THEECODE_USERNAME: res.data.userName,
            THEECODE_URL: process.env.REACT_APP_UIUrl,
            THEECODE_SETTINGS_URL:
              process.env.REACT_APP_UIUrl +
              `/control-panel/?username=${encodeURIComponent(
                res.data.userName
              )}&key=${encodeURIComponent(
                res.data.key
              )}&subscription_id=${encodeURIComponent(subscriptionId)}`,
            USER_PASSWORD: res.data.password,
            USER_SALT: res.data.salt,
            USER_EMAIL: res.data.email,
          };
          let resp = AuthPost(`configuration/publish`, params).then((resp) => {
            const subParam = {
              subscriptionss: [
                {
                  publish: "Published",
                },
              ],
            };
            const sub = {
              subscriptions: [
                {
                  verticalId: verticalId,
                  tenantId: tenantId,
                  businessName: params.BUSINESS_NAME,
                  status: "Active",
                },
              ],
            };
            // toast.success("Build triggered! You will receive an email once its ready")
            // updateSubscrip(sub)
          });
        }
      })
      .catch((err) => {});
  };

  const updateSubscrip = (sub) => {
    let response = AuthGet(`subscription/update/${subscriptionId}`, sub)
      .then((response) => {
        console.log(response);
        toast.success(
          "Build triggered! You will receive an email once its ready"
        );
      })
      .catch((error) => {
        console.log("er", error);
      });
  };
  let sendEmail = (id) => {
    AuthGet(`tenantmaster/domain/${id}`).then((res) => {
      if (res.statusCode === 200) {
        // toast.success("Build triggered! You will receive an email once its ready")
        return;
      }
    });
  };
  // console.warn(selectedTheme)
  let handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      tenantId: sessionStorage.getItem("tenantId"),
      themeId: selectedTheme.id,
      theme: selectedTheme.styleName,
      subscriptionId: sessionStorage.getItem("subscriptionId"),
    };
    sessionStorage.setItem("selectedthemeid", selectedTheme.id);

    if (!isThemeExists) {
      AuthFile("tenant-theme", params).then((res) => {
        //this.router.navigate(['/business-information'])

        // **** Need to delete once the docker setup completed ***

        // triggerBuild();
        // sendEmail(params.subscriptionId);
        sessionStorage.setItem("build", "Yes");
        triggerOtp();
        setTimeout(() => navigate("/verify-otp"), 2000);
      });
    } else {
      AuthFile(`tenant-theme/${params.subscriptionId}`, params, "patch").then(
        (res) => {
          if (res.statusCode === 200) {
            triggerBuild();
            publish(); //this will trigger build
            sessionStorage.setItem("build", "Yes");
            triggerOtp();
            setTimeout(() => navigate("/verify-otp"), 2000);
            // sendEmail(params.subscriptionId);
          }
        }
      );
    }
  };

  const triggerOtp = async () => {
    const otpBody = {
      email: sessionStorage.getItem("email"),
      phone: sessionStorage.getItem("phone"),
    };

    await Post(`tenant/generate-otp/${tenantId}`, otpBody)
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => console.log("Err::", err));
    return;
  };

  const triggerBuild = async () => {
    await Post(`tenant/generate-build/${subscriptionId}`, {});
    return;
  };

  return (
    <>
      <Toast />
      <div className="topHeader">
        <div className="displayFlex Alignitem_center width_100per">
          <div>
            {" "}
            <div className="logosection headerinherit">
              <div className="displayFlex">
                {/* <img src={menuicon} className="mobileMenuicon" /> */}
                <div className="brand_logo">
                  <img src={brandlogo} className="whiteFilter" />
                </div>
              </div>
            </div>
          </div>

          <div className="width_100per">
            <div>
              {" "}
              <Stepper />
            </div>
          </div>

          <div className="mr_20">
            {
              addNewSubscription === true ? (
                <UserAvatar logout={true} quickGuide={true} business={true} changePassword={true} profile={true} />
              ) : (
                <UserAvatar logout={true} />
              ) 
            }
          </div>
        </div>
      </div>

      <div className="fullScreen_bg p0">
        <div className="full_header">
          <div className="pagecontentblock">
            <div className="whiteboxblock">
              <h3 className="  mb_40  sectiontitle  ">
                Select a theme best suits your business
              </h3>
              <div className=" ">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="row p0 mb_30">
                    {allTheme.map((theme) => (
                      <div key={theme.id} className="col-md-6 mb-3">
                        <label className="SelectTheme">
                          <input type="radio" style={{ display: "none" }} />

                          <div
                            className="themeBox"
                            onClick={(e) => handleSelect(e, theme)}
                          >
                            <img src={theme?.logo} alt="theme_image" />
                          </div>

                          {selectedTheme.styleName === theme.styleName ? (
                            <div className="selectBtn selected">Selected</div>
                          ) : (
                            <div className="selectBtn">Select</div>
                          )}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="textAlign_center">
                    <button
                      className="Mainbutton"
                      onSubmit={(e) => handleSubmit(e)}
                      type="submit"
                      // style={{ display: show == 'No' ? '' : 'none' }}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className=" "></div>
          </div>
        </div>
      </div>
      {console.log("ssad", selectedTheme)}
    </>
  );
};

export default Theme;
